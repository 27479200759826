import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import HeaderWithImage from "../components/header-with-image"
import renderAst from "../components/render-markdown"

const gettingStartedPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        fileAbsolutePath: {
          regex: "/src/content/getting_started/getting-started/"
        }
      ) {
        htmlAst
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageAlt
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Get started with SixEye"
        description="In just a few simple steps, your company can have its own branded portal, powered by SixEye, to manage your projects remotely."
        image={markdownData.frontmatter.image.publicURL}
      />
      <HeaderWithImage
        title={markdownData.frontmatter.title}
        imgFluid={markdownData.frontmatter.image.childImageSharp.fluid}
        imageAlt={markdownData.frontmatter.imageAlt}
      />
      <Container text>
        {renderAst(markdownData.htmlAst)}
      </Container>
    </Layout>
  )
}
export default gettingStartedPage;
